<template>
<div class="tile is-parent block">
   <article class="tile is-child box inner">
     <div class="container">
        <iconComponent :title="horaires.statut" :icone="horaires.icon" :color="horaires.color" :bgcolor="horaires.bgcolor"></iconComponent>
        <hr class="solid">
     </div>
      <p class="subtitle has-text-weight-semibold">Supermarché</p>
      <p class="horaires">{{ horaires.ouverture_mag }}</p><br>
      <p class="subtitle has-text-weight-semibold">Bureau des membres</p>
      <p class="horaires">{{ horaires.ouverture_bdm }}</p>
      <p class="has-text-weight-semibold">{{ contact_bdm }}</p><br>
      <p class="subtitle has-text-weight-semibold">Services</p>
      <p class="horaires">{{ horaires.services }}</p><br>
      <p>Ces horaires sont susceptibles de varier lors de certains évènements. Consultez les urgences pour en savoir plus.</p>
   </article>
</div>
</template>

<script>
const IconComponent = () => import("@/components/IconComponent");

export default {
  name: "Horaires",
  data: function() {
    return {
      horaires: {
        statut: "",
        ouverture_mag: "",
        ouverture_bdm: "",
        services: "",
        icon: "fa-ban",
        bgcolor: "#ffcccb",
        color: "red",
      },
      contact_bdm: "Contact BDM : 01 86 95 91 90"
    };
  },
  mounted() {
    this.getHoraires();
  },
  methods: {
    getHoraires: function() {
      let jour = new Date(Date.now());
      this.horaires.ouverture_mag = "Lundi : fermé \nMardi au Samedi : 9h - 21h00 \nDimanche : 9h00 - 13h00"
      this.horaires.ouverture_bdm = "Mardi : 13h30 - 16h00 \nMercredi au Vendredi : 13h30 - 20h00 \nSamedi : 10h00 - 16h00"
      this.horaires.services = "Lundi : 9h - 12h | 13h - 16h \nMardi au Samedi : \n6h – 8h15 | 8h – 11h | 10h45 – 13h45 \n13h30 – 16h30 | 16h15 – 19h15 | 19h – 22h \nDimanche : 8h30 – 11h30 | 11h15 – 14h15"
      switch (jour.getDay()) {
        //dimanche
        case 0:
          if (jour.getHours() > 8 && jour.getHours() < 16) {
            this.horaires.statut = "Le magasin est ouvert";
            this.horaires.icon = "fa-shopping-basket";
            this.horaires.color = "green";
            this.horaires.bgcolor = "#90EE90";
          } else this.horaires.statut = "Le magasin est fermé";
          break;

        //lundi
        case 1:
          this.horaires.statut = "Actuellement fermé";
          break;

        //mardi-samedi
        default:
          if (jour.getHours() > 8 && jour.getHours() < 20) {
            this.horaires.statut = "Le magasin est ouvert";
            this.horaires.icon = "fa-shopping-basket";
            this.horaires.color = "green";
            this.horaires.bgcolor = "#90EE90";
          } else this.horaires.statut = "Le magasin est fermé";
          break;
      }
    }
  },
  components: {
    IconComponent
  }
};
</script>

<style lang="css" scoped>
.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}
.horaires {
   white-space: pre;
}
.block {
  flex: 1 1 0px;
  margin-bottom: 0;
}
.inner {
  width: 100%;
  overflow: scroll;
}
</style>
