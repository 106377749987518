<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Tableau de bord</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Tableau de bord</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated">
        <div class="tile is-ancestor">
          <prochainsServices></prochainsServices>
          <div class="tile is-parent is-success block">
            <article class="tile is-child box">
              <iconComponent title="Mon statut" icone="fa-user-o" color="#7972ff" bgcolor="#e2dffe"></iconComponent>
              <hr class="solid" />
              <div class="has-text-centered m-t-30 m-b-30">
                <p class="subtitle">
                  Statut :
                  <span style="color: #7972ff">{{ status }}</span>
                </p>
                <p class="subtitle" v-if="volant">Compteur vacations : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
                <div class="subtitle" v-else>
                  <p>Services anticipés : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
                  <p>Rattrapages : <span style="color: #7972ff"> {{ mesinfos.abcdCounter }} </span></p>
                </div>
                <p class="subtitle" v-if="mesinfos.type!='standard'">{{ team }}</p>
                <p class="subtitle" v-if="mesinfos.type!='standard' && volant">{{ next_service }}</p>
              </div>
              <iconComponent title="Formations" icone="fa-user-o" color="#196f3d" bgcolor="#a9dfbf"></iconComponent>
              <hr class="solid" />
              <div class="has-text-centered m-t-30 m-b-30">
                <p class="subtitle" >Vous pouvez vous inscrire aux différentes formations de coordos ou de coopérateurs en <a href="https://docs.google.com/forms/d/e/1FAIpQLSdV0DiSyGM9dj_CazoGo0_DFSmSukxKdubyvsmZZHW0xbLpLw/viewform">cliquant ici</a></p>
              </div>
              <hr class="solid" />
            </article>
          </div>
          <horaires></horaires>
        </div>
        <div class="tile is-ancestor" v-if="urgencyNotifications && urgencyNotifications.length>0">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <iconComponent
                title="Messages"
                icone="fa-envelope-o"
                color="#00ba60"
                bgcolor="#c3f5d7"
              ></iconComponent>
              <hr class="solid" />
              <div v-if="urgencyNotifications && urgencyNotifications.length>0" class="content">
                <span v-for="notif in urgencyNotifications" v-bind:key="notif._id">
                  <p class="subtitle">{{ notif.title }}</p>
                  <p>
                    {{ notif.message }}
                    <br />
                    <span
                      class="icon is-small"
                      v-if="typeof(notif.link) !== 'undefined' && notif.link"
                    >
                      <i class="fa fa-link"></i>&nbsp;
                      <a :href="notif.link" target="_blank">lien</a>
                    </span>
                    <br />
                    <br />
                  </p>
                </span>
              </div>
              <div v-else class="content">
                <p class="subtitle">Aucun message.</p>
              </div>
            </article>
          </div>
        </div>
        <div class="tile is-ancestor" v-if="eventNotifications && eventNotifications.length>0">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <iconComponent
                title="Événements"
                icone="fa-list-ul"
                color="#369eff"
                bgcolor="#deeefd"
              ></iconComponent>
              <hr class="solid" />
              <div v-if="eventNotifications && eventNotifications.length>0">
                <div class="content" v-for="notif in eventNotifications" v-bind:key="notif._id">
                  <p class="title">{{ notif.title }}</p>
                  <p class="subtitle">{{ formatDateOnly(notif.event.date) }}</p>
                  <p class="subtitle">{{ notif.message }}</p>
                  <p>
                    <a
                      :href="notif.link"
                      target="_blank"
                      class="button"
                      v-if="typeof(notif.link) !== 'undefined' && notif.link"
                    >Inscription / Ordre du Jour / Questions</a>
                  </p>
                </div>
              </div>
              <div v-else class="content">
                <p class="subtitle">Aucun événement à venir.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Horaires from "@/components/Horaires";
const ProchainsServices = () => import("@/components/ProchainsServices");
const IconComponent = () => import("@/components/IconComponent");
import date from "@/utils/formatDate";
import notificationService from "@/services/notificationServices";
import { authComputed } from "@/store/helpers.js";
import statusMixin from '@/mixins/statusMixin.js'

export default {
  name: "Home",
  data: function() {
    return {
      mesinfos: "",
      notifications: [],
      volant: ""
    };
  },
  mixins: [date, statusMixin],
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.volant = this.mesinfos.currentTeam.includes("volante")
    this.init();
  },
  computed: {
    urgencyNotifications: function() {
      return this.notifications.filter(notif => {
        return notif.type.toLowerCase().includes("urgency".toLowerCase());
      });
    },
    eventNotifications: function() {
      return this.notifications.filter(notif => {
        return notif.type.toLowerCase().includes("event".toLowerCase());
      });
    },
    ...authComputed
  },

  methods: {
    init: function() {
      notificationService
        .fetchCurrentsNotifications()
        .then(response => (this.notifications = response.data.notifications))
        .catch(() => {
          //console.log(error)
        });
      window.scrollTo(0, 0);
    }
  },
  components: {
    Horaires,
    ProchainsServices,
    IconComponent
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}
.block {
  flex: 1 1 0px;
  margin-bottom: 0;
}
</style>
