const statusMixin = {
    computed: {
        status: function() {
            var message = "";
            switch (this.mesinfos.status) {
              case "up_to_date":
                message = "Vous êtes à jour";
                break;
              case "alert":
                message = "Tu peux acheter. Mais tu as des rattrapages à faire avant ton prochain service.";
                break;
              case "suspended":
                message =
                  "Tu ne peux pas acheter parce que tu n’as pas fait tes rattrapages à temps. Demande une extension pour pouvoir faire tes courses.";
                break;
              case "blocked":
                message =
                  "Vous avez été bloqué. Merci de contacter le Bureau Des Membres";
                break;
              case "unsubscribed":
                message = "Tu dois te réinscrire dans une équipe pour pouvoir faire tes courses.";
                break;
              case "vacation":
                message = "Tu es en congés.";
                break;
              case "exempted":
                message = "Tu es en congés. Tu peux faire tes courses, mais tu n'as pas à faire de service.";
                break;
              case "not_concerned":
                message = "Non concerné.";
                break;
              case "delay":
                message = "Délais.";
                break;
              case "unpayed":
                message = "Impayé. Merci de contacter le Bureau Des Membres";
                break;
              default:
                message = "Statut indisponible ...";
            }
            return message;
        },
        next_service: function() {
            var next_count = this.$moment(this.mesinfos.nextFtopRegistrationDate).format("dddd Do MMMM YYYY");
            return "Prochain décompte le " + next_count;
            // switch (this.mesinfos.counter) {
            //     case 0:
            //         message = "Vous devez faire un service avant le "  + next_count;
            //         break;
            //     case (this.mesinfos.counter < 0):
            //         message = "Vous avez " + -(-this.mesinfos.counter - 1) + " services à faire avant le "  + next_count;
            //         break;
            //     default:
            //         message = "Prochain décompte le " + next_count;
            //     }
            //     return message;
            },
            team: function () {
              var message = "";
              if (this.mesinfos.currentTeam) {
                if (this.volant) {
                  if (this.mesinfos.currentTeam.includes("A")) {
                    message = "Equipe volante - décompte semaine A"
                  } else if (this.mesinfos.currentTeam.includes("B")) {
                    message = "Equipe volante - décompte semaine B"
                  } else if (this.mesinfos.currentTeam.includes("C")) {
                    message = "Equipe volante - décompte semaine C"
                  } else if (this.mesinfos.currentTeam.includes("D")) {
                    message = "Equipe volante - décompte semaine D"
                  }
                } else {
                    message = "Equipe " + this.mesinfos.currentTeam[0] + " " + this.mesinfos.currentTeam.substring(1);
                }
              }
              return message
            },
    }
}

export default statusMixin
